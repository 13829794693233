import logo from './logo.svg';
import Form from './Form';
import './App.css';
import NavBar from './NavBar/NavBar';
function App() {
  return (
    <div>
    <NavBar />
   <Form />
    </div>
  );
}

export default App;
